.custom-datatable {

    th,
    td {
        button {
            &:hover svg {
                color: #fff;
            }
        }

        svg {
            width: 16px;
            height: 16px;
            color: #3f50b5
        }
    }

    .MuiPaper-elevation1 {
        box-shadow: none !important;
    }

    .MuiTableCell-paddingCheckbox {
        background-color: #fff !important;
    }

    .mui-datatables-16062w7-MuiTableRow-root:hover {
        cursor: pointer;
    }

    // MUI datatable horizontal scroll bar -----------


    ::-webkit-scrollbar {
        width: 2px;
        -webkit-appearance: none;
        width: 10px !important;
        height: 10px !important;
        background: #f3f2f2;
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 13px;
    }

    // --------------------------------

    // These remove later

    // .MuiTablePagination-displayedRows{
    //     display: none!important;
    // }

}



.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiTableCell-paddingCheckbox {
    background-color: #fff !important;
}


.css-wsew38 {
    margin-top: 8px !important;
}

.css-wsew38 {
    align-items: center !important;
}

.css-1atu56z-MuiPaper-root {
    z-index: 999 !important;
}






.css-eu52pj-MuiInputBase-root-MuiOutlinedInput-root {
    width: 200px !important;
}

.css-1svizdt {
    width: 200px !important;
}


.MuiTypography-body1 {
    max-width: none !important;
}

.MuiTableContainer-root {
    min-height: 430px;
    display: flex;
    align-items: flex-start;
}



.hover-circle:hover {
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.hover-circle:hover {
    background-color: #F5F5F5;
}