.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    position: relative;
    max-width: 600px;
    width: 100%;
    transform: scale(0.9);
    transition: transform 0.5s ease-in-out;
}

.fade-in {
    opacity: 1;
    transform: scale(1);
}

.fade-out {
    opacity: 0;
    transform: scale(0.9);
}
