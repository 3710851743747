@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
  --primary-color: #4998c9;
  --complementary-color: #c9496a;
  --analogous-color-1: #4971c9;
  --analogous-color-2: #49c9a8;
  --accent-color-1: #c99949;
  --accent-color-2: #c9b449;
  --light-gray: #f5f5f5;
  --dark-gray: #333333;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfcfe !important;
}

code {
  font-family: "Poppins", sans-serif !important;
}

.container {
  max-width: 1400px !important;
}

/* Accordion */

.accordion-button:focus {
  box-shadow: none !important;
  z-index: 2;
}

.accordion-item {
  margin-bottom: 1rem;
  position: relative;
}

.accordion-button::after {
  display: none;
}

.accordion-button,
.accordion-body {
  z-index: 1;
}

.accordion-list {
  list-style-type: none;
  padding-left: 0;
}

.accordion-list li {
  position: relative;
  padding-left: 50px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.accordion-list li::before {
  content: "\25AA";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #8cbddb;
  font-size: 2rem;
  margin-right: 15px;
}

.collapse {
  visibility: visible !important;
}

.typography-h1 {
  font-size: 3.05rem !important;
  color: #1e1b4b;
}

.typography-h2 {
  font-size: 2rem !important;
  color: #1e1b4b;
}

.typography-h3 {
  font-size: 1.95rem !important;
  color: #1e1b4b;
}

.typography-h4 {
  font-size: 1.56rem !important;
  color: #1e1b4b;
}

.typography-h5 {
  font-size: 1.25rem !important;
  color: #1e1b4b;
}

.typography-h6 {
  font-size: 1rem !important;
  color: #1e1b4b;
}

.typography-h1-sky {
  font-size: 3.05rem !important;
  color: #40aade;
}
.typography-h2-sky {
  font-size: 2rem !important;
  color: #40aade;
}
.typography-h3-sky {
  font-size: 1.95rem !important;
  color: #40aade;
}
.typography-h4-sky {
  font-size: 1.56rem !important;
  color: #40aade;
}
.typography-h5-sky {
  font-size: 1.25rem !important;
  color: #40aade;
}
.typography-h6-sky {
  font-size: 1rem !important;
  color: #40aade;
}

.typography-p {
  /* font-size: 1.0625rem !important;  */
  font-size: 1rem !important;
  line-height: 28px !important;
  color: #6e6d6d !important;
}

.typography-p2 {
  /* font-size: 1.0625rem !important; */
  font-size: 1rem !important;
  line-height: 28px !important;
}

@media (max-width: 1200px) {
  .typography-h1 {
    font-size: 2.75rem !important;
  }

  .typography-h2 {
    font-size: 2.2rem !important;
  }

  .typography-h3 {
    font-size: 1.85rem !important;
  }

  .typography-h4 {
    font-size: 1.5rem !important;
  }

  .typography-h5 {
    font-size: 1.2rem !important;
  }

  .typography-h6 {
    font-size: 0.95rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 1rem !important;
  }
}

@media (max-width: 991px) {
  .typography-h1 {
    font-size: 2.44rem !important;
  }

  .typography-h2 {
    font-size: 2.05rem !important;
  }

  .typography-h3 {
    font-size: 1.73rem !important;
  }

  .typography-h4 {
    font-size: 1.44rem !important;
  }

  .typography-h5 {
    font-size: 1.15rem !important;
  }

  .typography-h6 {
    font-size: 0.95rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 1rem !important;
  }
}

@media (max-width: 767px) {
  .typography-h1 {
    font-size: 2.05rem !important;
  }

  .typography-h2 {
    font-size: 1.73rem !important;
  }

  .typography-h3 {
    font-size: 1.44rem !important;
  }

  .typography-h4 {
    font-size: 1.25rem !important;
  }

  .typography-h5 {
    font-size: 1rem !important;
  }

  .typography-h6 {
    font-size: 0.85rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 0.9375rem !important;
  }
}

@media (max-width: 576px) {
  .typography-h1 {
    font-size: 1.75rem !important;
  }

  .typography-h2 {
    font-size: 1.5rem !important;
  }

  .typography-h3 {
    font-size: 1.3rem !important;
  }

  .typography-h4 {
    font-size: 1.15rem !important;
  }

  .typography-h5 {
    font-size: 0.95rem !important;
  }

  .typography-h6 {
    font-size: 0.75rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 0.875rem !important;
  }
}

@media (max-width: 1200px) {
  .typography-h1 {
    font-size: 2.75rem !important;
  }

  .typography-h2 {
    font-size: 2.2rem !important;
  }

  .typography-h3 {
    font-size: 1.85rem !important;
  }

  .typography-h4 {
    font-size: 1.5rem !important;
  }

  .typography-h5 {
    font-size: 1.2rem !important;
  }

  .typography-h6 {
    font-size: 0.95rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 1rem !important;
  }
}

@media (max-width: 991px) {
  .typography-h1 {
    font-size: 2.44rem !important;
  }

  .typography-h2 {
    font-size: 2.05rem !important;
  }

  .typography-h3 {
    font-size: 1.73rem !important;
  }

  .typography-h4 {
    font-size: 1.44rem !important;
  }

  .typography-h5 {
    font-size: 1.15rem !important;
  }

  .typography-h6 {
    font-size: 0.95rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 1rem !important;
  }
}

@media (max-width: 767px) {
  .typography-h1 {
    font-size: 2.05rem !important;
  }

  .typography-h2 {
    font-size: 1.73rem !important;
  }

  .typography-h3 {
    font-size: 1.44rem !important;
  }

  .typography-h4 {
    font-size: 1.25rem !important;
  }

  .typography-h5 {
    font-size: 1rem !important;
  }

  .typography-h6 {
    font-size: 0.85rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 0.9375rem !important;
  }
}

@media (max-width: 576px) {
  .typography-h1-sky {
    font-size: 1.75rem !important;
  }

  .typography-h2-sky {
    font-size: 1.5rem !important;
  }

  .typography-h3-sky {
    font-size: 1.3rem !important;
  }

  .typography-h4-sky {
    font-size: 1.15rem !important;
  }

  .typography-h5-sky {
    font-size: 0.95rem !important;
  }

  .typography-h6-sky {
    font-size: 0.75rem !important;
  }

  .typography-p,
  .typography-p2 {
    font-size: 0.875rem !important;
  }
}

@media (min-width: 0px) {
  .container {
    padding: 4px 10px !important;
  }
}

@media (min-width: 320px) {
  .container {
    padding: 4px 10px !important;
  }
}

@media (min-width: 576px) {
  .container {
    padding: 6px 14px !important;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 6px 14px !important;
  }
}

@media (min-width: 992px) {
  .container {
    padding: 8px 18px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    padding: 8px 18px !important;
  }
}

.jWSwi_R_Xl7kPjUhuQoo {
  display: none !important;
}

.calendly-badge-widget .calendly-badge-content {
  box-shadow: none !important;
  background-color: #40aade !important;
  color: #ffffff !important;
  transition: background-color 0.3s ease !important;
}

.calendly-badge-widget .calendly-badge-content:hover {
  background-color: #393939 !important;
}
