.glass-effect {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
}

.glass-effect h1 {
  font-family: "Playfair Display", serif;
}

.image-slider-container .image-slider-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 500px;
  object-fit: cover;
}

.consulting-info-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem;
  margin-top: 15%;
  width: 100%;
}

.consulting-info-container h1 {
  font-weight: bold;
  color: #2d2d2d;
  text-transform: capitalize;
}

.consulting-info-container p {
  font-size: 1rem;
  color: #909090;
  line-height: 1.6;
}

.navigation-buttons-pot {
  position: absolute;
  top: 0;
  left: 80%;
  transform: translate(-50%, -50%);
  display: flex;
}

.navigation-buttons svg {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .navigation-buttons-pot {
    display: none;
  }
}

@media (min-width: 1200px) {
  .glass-effect h1 {
    width: 55%;
  }
}

@media (max-width: 1200px) {
  .image-slider-container .image-slider-img {
    width: 100%;
    height: 50vh;
  }

  .consulting-info-container h1 {
    margin-top: 25px;
  }

  .consulting-info {
    position: static;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 30%;
  }

  .glass-effect {
    border-radius: 0px;
    width: 85%;
  }

  .consulting-info-container {
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .navigation-buttons {
    display: none;
  }

  .navigation-buttons svg {
    width: 70px;
    height: 70px;
  }

  .navigation-buttons-pot svg {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 991px) {
  .image-slider-container .image-slider-img {
    width: 100%;
    height: 60vh;
  }

  .consulting-info-container h1 {
    margin-top: 20px;
  }

  .consulting-info {
    position: static;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 45%;
  }

  .glass-effect {
    border-radius: 0px;
    width: 85%;
  }

  .consulting-info-container {
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .navigation-buttons svg {
    width: 60px;
    height: 60px;
  }

  .navigation-buttons-pot svg {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 768px) {
  .image-slider-container .image-slider-img {
    width: 100%;
    height: 50vh;
  }

  .consulting-info-container h1 {
    margin-top: 15px;
  }

  .consulting-info {
    position: static;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 50%;
  }

  .glass-effect {
    border-radius: 0px;
    width: 85%;
  }

  .consulting-info-container {
    padding: 1.25rem;
    margin-top: 1.5rem;
  }

  .navigation-buttons svg {
    width: 50px;
    height: 50px;
  }

  .navigation-buttons-pot svg {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 576px) {
  .image-slider-container .image-slider-img {
    width: 100%;
    height: 50vh;
  }

  .consulting-info-container h1 {
    margin-top: 10px;
  }

  .consulting-info {
    position: static;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 85%;
  }

  .glass-effect {
    border-radius: 0px;
    width: 85%;
  }

  .consulting-info-container {
    padding: 1rem;
    margin-top: 1rem;
  }

  .navigation-buttons svg {
    width: 40px;
    height: 40px;
  }

  .navigation-buttons-pot svg {
    width: 40px;
    height: 40px;
  }
}
