.custom-btn {
    background-color: #4da6ff; /* Custom blue color */
    border-radius: 50px; /* Rounded corners */
    padding: 10px 20px; /* Custom padding */
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    border: none; /* No border */
  }
  

  .custom-input {
    border: none;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 0 1px #ced4da;
    padding: 10px;
}

.custom-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px #80bdff;
}

.custom-input-error {
    box-shadow: 0 0 0 2px #f44336;
}
