.video-wrapper {
    position: relative;
    max-width: 100%;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 64px;
    color: white;
    cursor: pointer;
    z-index: 10;
    transition: opacity 0.3s ease;
  }
  
  .play-button:hover {
    opacity: 0.8;
  }
  
  video {
    border-radius: 24px;
    width: 100%;
  }
  